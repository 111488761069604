<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to"
    class="action-button rounded-xl uppercase"
    :class="[
      { 'fixed-width': !dynamicWidth },
      { 'rounded-full': fullyRounded },
      { 'shadow-xl': shadow },
      { 'action-link': to },
      transparent
        ? [
            'transparent-action',
            {
              'transparent-accent': accent,
              'transparent-primary': primary,
              'transparent-secondary': secondary,
            },
          ]
        : [
            'solid-action',
            {
              'solid-accent': accent,
              'solid-primary': primary,
              'solid-secondary': secondary,
            },
          ]
    ]"
    @click="$emit('click')"
  >
    <slot />
  </component>
</template>

<script lang="ts">

export default {
  name: 'ActionButton',
  props: {
    accent: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    dynamicWidth: {
      type: Boolean,
      default: false
    },
    fullyRounded: {
      type: Boolean,
      default: false
    },
    shadow: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
:disabled {
  @apply opacity-50;
  @apply cursor-not-allowed;
}

.fixed-width {
  @apply w-56;
}

.action-button {
  @apply h-11;
  @apply tracking-wide;
}

.action-link {
  @apply flex items-center justify-center;
}

.transparent-action {
  @apply bg-transparent;
  @apply border-2;
  @apply transition-colors;
  @apply hover:text-white;
}

.solid-action {
  @apply text-white;
}

.transparent-accent {
  @apply bg-transparent border-accent;
  @apply text-accent hover:bg-accent;
}

.transparent-primary {
  @apply bg-transparent border-primary;
  @apply text-primary hover:bg-primary;
}

.transparent-secondary {
  @apply bg-transparent border-secondary;
  @apply text-secondary hover:bg-secondary;
}

.solid-accent {
  @apply bg-accent border-accent;
}

.solid-primary {
  @apply bg-primary border-primary;
}

.solid-secondary {
  @apply bg-secondary border-secondary;
}
</style>
