<template>
  <div>
    <Navbar v-if="routeName"/>
      <div id="app" v-bind:style="[noGreyBG ? {} : {'background': '#ECF0F3'}]">
        <router-view/>
      </div>
    <airis-modal-wrapper :show="showInactiveModal" action-required>
      <div class="flex flex-col items-center bg-white rounded-2xl">
        <div
          class="flex flex-col items-center border-b border-gray-200 px-6 py-4"
        >
          <p class="text-lg mb-1">Inactive</p>
          <p class="text-xs">Are you still here? You will be logged out after 1 hour of inactivity.</p>
        </div>
        <span class="text-sm my-2 text-blue-600 cursor-pointer" @click="showInactiveModal = false">OK</span>
      </div>
    </airis-modal-wrapper>
  </div>
</template>

<script>
import Pace from '../node_modules/pace-js'
import Navbar from '@/components/Navbar.vue'

// page loading indicator
Pace.start()

export default {
  name: 'App',
  data() {
    return {
      events: ['click', 'mousedown', 'scroll', 'keypress'],
      showInactiveModal: false,
      warningTime: 55, // 55 minute warning
      logoutTime: 60, // 60 minute logout
      warningTimer: null,
      logoutTimer: null
    }
  },
  watch: {
    $route (to, from) {
      this.$store.commit('clearInterval')
      document.body.style.backgroundImage = ""
    }
  },
  computed: {
    noGreyBG: function() {
      if (this.$route.name === 'LoginPage' ||
      this.$route.name === 'UserPage' ||
      this.$route.name === 'UserAccount' ||
      this.$route.name === 'UserData' ||
      this.$route.name === 'UserBilling' ||
      this.$route.name === 'UserSupport' ||
      this.$route.name === 'ForgotPassword' ||
      this.$route.name === 'Reset') {
        return true
      } else {
        return false
      }
    },
    routeName: function() {
      let flag = true
      if (this.$route.name !== null) {
        if (this.$route.name === 'LoginPage' ||
        this.$route.name.includes('User') ||
        this.$route.name === 'Activate' ||
        this.$route.name === 'TermsAndConditions' ||
        this.$route.name === 'PrivacyPolicy' ||
        this.$route.name === 'Reset' ||
        this.$route.name === 'ForgotPassword') {
          flag = false
        }
      } else {
        flag = false
      }
      return flag
    }
  },
  mounted() {
    this.events.forEach(element => {
      window.addEventListener(element, this.resetTimer)
    });
    if (this.$route.name !== 'LoginPage' &&
    this.$route.name !== 'Activate' &&
    this.$route.name !== 'TermsAndConditions' &&
    this.$route.name !== 'PrivacyPolicy' &&
    this.$route.name !== 'Reset' &&
    this.$route.name !== 'ForgotPassword') {
      this.setTimer()
    }
  },
  destroyed() {
    this.events.forEach(element => {
      window.removeEventListener(element, this.resetTimer)
    });
    if (this.$route.name !== 'LoginPage' &&
    this.$route.name !== 'Activate' &&
    this.$route.name !== 'TermsAndConditions' &&
    this.$route.name !== 'PrivacyPolicy' &&
    this.$route.name !== 'Reset' &&
    this.$route.name !== 'ForgotPassword') {
      this.resetTimer()
    }
  },
  methods: {
    setTimer() {
      this.warningTimer = setTimeout(this.warningMessage, this.warningTime * 60 * 1000) // 55 * 60 * 1000 = 55min
      this.logoutTimer = setTimeout(this.logout, this.logoutTime * 60 * 1000) // 60 * 60 * 1000 = 1hr
    },
    resetTimer() {
      clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)
      if (this.$route.name !== 'LoginPage' &&
      this.$route.name !== 'Activate' &&
      this.$route.name !== 'TermsAndConditions' &&
      this.$route.name !== 'PrivacyPolicy' &&
      this.$route.name !== 'Reset' &&
      this.$route.name !== 'ForgotPassword') {
        this.setTimer()
      }
    },
    warningMessage() {
      if (this.$route.name.includes('User')) {
        this.showInactiveModal = true
      } else {
        this.$buefy.dialog.alert({
          title: 'Inactive',
          message: 'Are you still here? You will be logged out after 1 hour of inactivity.'
        })
      }
    },
    logout() {
      this.$router.push({ path: '/' })
      window.sessionStorage.clear()
      window.localStorage.clear()
    }
  },
  components: {
    Navbar
  }
}
</script>

<style lang="scss">

// Import Bulma's core
@import "~bulma/sass/utilities/functions";

$primary: #2196f3;
$primary-invert: findColorInvert($primary);

html, body {
  font-family: 'Open Sans', Verdana, Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.5;
  height: 10000000%;
  background: #ECF0F3;
  background-size: cover;
  background-repeat: repeat-y;
}

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

</style>

<style>
@import 'assets/style.css';
</style>

<style>
.profile-img {
  background-image: linear-gradient(
    to bottom,
    rgba(51, 204, 246, 1) 20%,
    rgba(42, 141, 209, 1) 80%
  );
  @apply h-20 w-20;
  @apply flex items-center justify-center;
  @apply text-white font-sans font-semibold tracking-wider rounded-full;
}
</style>
