import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag"
import "./main.scss";
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import 'vue-search-select/dist/VueSearchSelect.css'
import createPersistedState from 'vuex-persistedstate'
import vuetify from '@/plugins/vuetify' // path to vuetify export

import 'tailwindcss/tailwind.css';
import AirisUI from '@airis-ui/vue';
import '@airis-ui/vue/dist/airis-ui.css';
Vue.use(Vuex)
Vue.config.productionTip = false;
Vue.use(Buefy)
Vue.use(AirisUI, { components: ['dropdown', 'modal'] });

const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  state: {
    bearerToken: '',
    url: process.env.VUE_APP_API_BASE,
    building: {},
    panel: {},
    breaker: {},
    panelUpdateInterval: null,
    panelUpdate: null,
    currentlyViewedBreakerId: null,
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59),
    // usersStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    // usersEndDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59),
    selectedUser: {},
    interval: null,
    amps: {},
    volts: {},
    power: undefined,
    energy: undefined
  },
  mutations: {
    setBearerToken(state, token) {
      state.bearerToken = token
    },
    setBuilding(state, building) {
      state.building = building
      localStorage.setItem('building', building)
    },
    setPanel(state, panel) {
      state.panel = panel
      localStorage.setItem('panel', panel)
    },
    setBreaker(state, breaker) {
      state.breaker = breaker
    },
    setPanelUpdateInterval(state, interval) {
      state.panelUpdateInterval = interval
    },
    clearPanelUpdateInterval(state) {
      clearInterval(state.panelUpdateInterval)
      state.panelUpdateInterval = null
    },
    setCurrentlyViewedBreakerId (state, breakerId) {
      state.currentlyViewedBreakerId = breakerId
    },
    setStartDate(state, startDate) {
      state.startDate = startDate
    },
    setEndDate(state, endDate) {
      state.endDate = endDate
    },
    // setUsersStartDate(state, startDate) {
    //   state.usersStartDate = startDate
    // },
    // setUsersEndDate(state, endDate) {
    //   state.usersEndDate = endDate
    // },
    setSelectedUser(state, user) {
      state.selectedUser = user
    },
    startInterval(state, interval) {
      state.interval = interval
    },
    clearInterval(state) {
      clearInterval(state.interval)
    },
    setVolts(state, volts) {
      state.volts = volts
    },
    setAmps(state, amps) {
      state.amps = amps
    },
    setPower(state, power) {
      state.power = power
    },
    setEnergy(state, energy) {
      state.energy = energy
    }
  },
  actions: {
    clearPersistedState() {
      window.sessionStorage.clear();
    },
    authorizedFetch: async (context, { method, endpoint, body = null, queryParams = '' }) => {
      // convert queryParams object to a URLSearchParam
      if (queryParams !== '') {
        queryParams = new URLSearchParams(queryParams).toString()
        queryParams = '?' + queryParams
      }
      if (body !== null) {
        body = JSON.stringify(body)
      }
      const url = context.state.url + endpoint + queryParams
      let token = context.state.bearerToken
      if (token === null || token === '') {
        token = window.localStorage.getItem('user-token')
        // console.log("Getting token from local storage")
      }
      const response = await fetch(url, {
        method: method,
        body: body,
        // injects the bearer token on any fetch
        headers: new Headers({
          Authorization: token
        })
      })
      if (response.status === 401) {
        window.location.replace('/')
      }
      const newBearerToken = response.headers.get('Authorization')
      if (newBearerToken !== null) {
        context.commit('setBearerToken', newBearerToken, { root: true })
        localStorage.setItem('user-token', newBearerToken)
      }
      // convert to json before sending
      return response
    },
    authorizedFetchImg: async (context, { method, endpoint, body = null, queryParams = '' }) => {
      // convert queryParams object to a URLSearchParam
      if (queryParams !== '') {
        queryParams = new URLSearchParams(queryParams).toString()
        queryParams = '?' + queryParams
      }
      const url = context.state.url + endpoint + queryParams
      let token = context.state.bearerToken
      if (token === null || token === '') {
        token = window.localStorage.getItem('user-token')
        // console.log("Getting token from local storage")
      }
      const response = await fetch(url, {
        method: method,
        body: body,
        // injects the bearer token on any fetch
        headers: new Headers({
          Authorization: token
        })
      })
      if (response.status === 401) {
        window.location.replace('/')
      }
      const newBearerToken = response.headers.get('Authorization')
      if (newBearerToken !== null) {
        context.commit('setBearerToken', newBearerToken, { root: true })
        localStorage.setItem('user-token', newBearerToken)
      }
      // convert to json before sending
      return response
    }
  },
  modules: {}
})

Vue.use(VueGtag, {
  config: { id: "GTM-5GG62LZ" }
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
