<template>
  <nav id="topbar" class="navbar">
    <div class="container p-0">
      <b-navbar>
        <!-- Logo -->
        <template #brand>
          <b-navbar-item tag="router-link" id="logo-container" :to="userMainPage" class="navbar-item brand-text">
            <img id="logo" src="../assets/logoEvoluteSide.png" style="max-width:200px">
          </b-navbar-item>
        </template>
        <!-- Menu links -->
        <template #start>
          <b-navbar-dropdown label="Administration" v-if="user.class == 'superuser'">
            <b-navbar-item tag="router-link" to="/createUsers" @click.native="condToggle"> Create Users </b-navbar-item>
            <b-navbar-item tag="router-link" to="/organizations" @click.native="condToggle"> Organizations </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-dropdown label="Onboarding" v-if="user.class == 'superuser'">
            <b-navbar-item tag="router-link" to="/managerHome" @click.native="condToggle"> Pre-User Info </b-navbar-item>
            <b-navbar-item tag="router-link" to="/technicianHome" @click.native="condToggle"> Panel Positions </b-navbar-item>
            <b-navbar-item tag="router-link" to="/uploadImage" @click.native="condToggle"> Upload Image </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-dropdown label="Scheduling" v-if="user.class == 'superuser'">
            <b-navbar-item tag="router-link" to="/locations" @click.native="condToggle"> Locations </b-navbar-item>
            <b-navbar-item tag="router-link" to="/loadThrottling" @click.native="condToggle"> Load Throttling </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-dropdown label="Billing" v-if="user.class == 'superuser' || user.class == 'masteradmin'">
            <b-navbar-item tag="router-link" to="/paymentPlans" @click.native="condToggle"> Payment Plans </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-dropdown label="Reporting" v-if="user.class == 'superuser' || user.class == 'masteradmin' || user.class == 'admin'">
            <b-navbar-item tag="router-link" to="/billingReports" @click.native="condToggle"> Billing Reports </b-navbar-item>
            <b-navbar-item tag="router-link" to="/sessionReports" v-if="user.class == 'superuser'" @click.native="condToggle"> Session Reports </b-navbar-item>
            <b-navbar-item tag="router-link" to="/breakdownReports" v-if="user.class == 'superuser' || user.class == 'masteradmin'" @click.native="condToggle"> Breakdown Reports </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-item tag="router-link" to="/statistics" v-if="user.class == 'superuser'" @click.native="condToggle"> Statistics </b-navbar-item>
          <b-navbar-item tag="router-link" to="/uploadImage" v-if="user.class == 'manager' || user.class == 'technician'" @click.native="condToggle"> Upload Image </b-navbar-item>
          <b-navbar-dropdown label="Debug" v-if="user.class == 'superuser'">
            <b-navbar-item tag="router-link" to="/debugpage" @click.native="condToggle"> Live </b-navbar-item>
            <b-navbar-item tag="router-link" to="/historypage" @click.native="condToggle"> History </b-navbar-item>
          </b-navbar-dropdown>
          <b-navbar-item tag="a" class="navbar-item" v-on:click.native="showSettingsModal = true"> Settings </b-navbar-item>
          <b-navbar-item tag="router-link" to="/" v-on:click.native="clearStorage"> Logout </b-navbar-item>
        </template>
      </b-navbar>
      <b-modal :active.sync="showSettingsModal" has-modal-card>
        <SettingsModal :user="user"/>
      </b-modal>
    </div>
  </nav>
</template>

<script>
import SettingsModal from '@/views/modals/SettingsModal'

export default {
  name: 'Navbar',
  components: {
    SettingsModal
  },
  data () {
    return {
      showNav: false,
      showSettingsModal: false,
      user: {}
    }
  },
  mounted () {
    this.fetchData() // calls a function that fills in our data for us
  },
  computed: {
    userMainPage: function() {
      let route = ""
      switch (this.user.class) {
        case 'superuser':
          route = '/buildingSelection'
          break;
        case 'supervisor':
          route = '/buildingSelection'
          break;
        case 'masteradmin':
          route = '/buildingSelection'
          break;
        case 'admin':
          route = '/panelSelection'
          break;
        case 'manager':
          route = '/managerHome'
          break;
        case 'technician':
          route = '/technicianHome'
          break;
      }
      return route
    }
  },
  methods: {
    clearStorage() {
      window.sessionStorage.clear()
      window.localStorage.clear()
    },
    fetchData () {
      this.$store.dispatch('authorizedFetch', {
        method: 'get',
        endpoint: '/api/user/info'
      })
        .then(response => {
          return response.json()
        })
        .then(json => {
          this.user = json
        })
        .catch(err => {
          console.log(err)
        })
    },
    toggle () {
      // toggle between displaying and hiding mobile menu
      this.showNav = !this.showNav
    },
    condToggle () {
      // if user clicks on mobile menu, hide the menu
      if (this.showNav) {
        this.toggle()
      }
    }
  }
}
</script>

<style>
#topbar {
  margin-bottom: 1rem;
}

.navbar-item.brand-text {
  font-weight: 300;
}
.navbar-item, .navbar-link {
  font-size: 14px;
  font-weight: 700;
}
#logo-container:hover, #logo-container {
  background-color: #fff;
}
#logo {
  max-height: 4rem;
}
</style>
