<template>
    <form action="">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Change Password</p>
            </header>
            <section class="modal-card-body">
                <b-field label="Enter Old Password">
                  <b-input v-model="oldPassword" type="password" password-reveal required @keyup.enter.native="changePassword()"></b-input>
                </b-field>
                <b-field label="Enter New Password">
                  <b-input v-model="password1" type="password" password-reveal required @keyup.enter.native="changePassword()"></b-input>
                </b-field>
                <b-field label="Enter New Password again">
                  <b-input v-model="password2" type="password" password-reveal required @keyup.enter.native="changePassword()"></b-input>
                </b-field>
                <p>NOTE: New passwords require a letter, a number, a minimum of 8 characters</p>
              <a style="color:blue" @click="resetPassword()">Forgot your password?</a>
            </section>

            <footer class="modal-card-foot" style="display: flex;justify-content: flex-end">
                <button class="button" type="button" @click="$parent.close()">Close</button>
                <button class="button is-primary" type="button" @click="changePassword()">Submit</button>
            </footer>

        </div>
    </form>
</template>

<script>

export default {
  data () {
    return {
      password1: "",
      passsword2: "",
      oldPassword: ""
    }
  },
  props: ['email'],
  methods: {
    changePassword() {
      if (this.password1 !== this.password2) {
        this.$buefy.toast.open({ message: "Passwords do not match", type: "is-danger" })
        return
      }
      if (this.password1 === "" || this.password2 === "") {
        this.$buefy.toast.open({ message: "Please specify password", type: "is-danger" })
        return
      }
      if (this.password1 === this.oldPassword) {
        this.$buefy.toast.open({ message: "New password is the same as old password", type: "is-danger" })
        return
      }
      if (!this.password1.match(/[a-zA-Z]/g) || !this.password1.match(/[0-9]/g) || !(this.password1.length >= 8)) {
        this.$buefy.toast.open({ message: "New passwords require a letter, a number, and a minimum of 8 characters", type: "is-danger" })
        return
      }
      const token = this.$store.state.bearerToken.substring(7)
      var body = {
        password: this.oldPassword,
        token: token
      }
      // check to see if oldPassword matches
      this.$store.dispatch('authorizedFetch', {
        method: 'post',
        endpoint: '/id/validatePassword',
        body: body
      })
        .then(response => {
          return response.json()
        })
        .then(json => {
          if (json.success) {
            // if the old password is valid
            body.password = this.password1
            // update the password
            this.$store.dispatch('authorizedFetch', {
              method: 'post',
              endpoint: '/id/updatePassword',
              body: body
            })
              .then(response => {
                return response.json()
              })
              .then(json => {
                if (json.success) {
                  this.$buefy.toast.open({ message: "Password changed!", type: "is-success" })
                  this.$parent.close()
                }
              })
          } else {
            // if the old password is invalid
            this.$buefy.toast.open({ message: "Old password is incorrect. Please try again.", type: "is-danger" })
          }
        })
    },
    resetPassword() {
      this.$buefy.dialog.confirm({
        title: 'Reset Password',
        message: `Are you sure you want to reset your password? An email will be sent for you to create a new password.`,
        confirmText: 'Reset Password',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('authorizedFetch', {
            method: 'post',
            endpoint: '/id/requestPasswordReset',
            body: { email: this.email }
          })
            .then(response => {
              return response.json()
            })
            .then(json => {
              if (json.success) {
                this.$buefy.toast.open({ message: "Sent reset email.", type: "is-success" })
                this.$parent.close()
              } else {
                this.$buefy.toast.open({ message: "Error occured when sending reset email.", type: "is-danger" })
              }
            })
        }
      })
    }
  }
}
</script>
