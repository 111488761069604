<template>
  <form action="">
    <div class="modal-card" style="width: 100vw; max-width: 500px; overflow: visible; margin: auto;">

      <header class="modal-card-head">
        <p class="modal-card-title">Settings</p>
      </header>
      <section class="modal-card-body" style="overflow: visible">

        <b-field label="Name">
            <b-input
                type="text"
                v-model="name"
                required>
            </b-input>
        </b-field>

        <b-field label="Email">
            <b-input
                type="text"
                v-model="email"
                required>
            </b-input>
        </b-field>

        <b-field>
          <button
            style="margin-top:20px;width:100%;"
            class="button is-primary"
            type="button"
            @click="changePassword"
          >
            <b-icon icon="key"></b-icon>
            <span> Change Password </span>
          </button>
        </b-field>

        <b-field>
          <button
            style="margin-top:20px;width:100%;"
            class="button is-primary"
            type="button"
            @click="multiFactorAuth"
          >
            <b-icon icon="lock"></b-icon>
            <span> Multi-Factor Authentication </span>
          </button>
        </b-field>
      </section>

      <footer class="modal-card-foot" style="display: flex;justify-content: flex-end">
          <button class="button" type="button" @click="$parent.close()">Close</button>
          <button class="button is-primary" type="button" @click="saveInfo">Save</button>
      </footer>

      <b-modal :active.sync="showPasswordModal" has-modal-card>
        <ChangePasswordModal :email="email"></ChangePasswordModal>
      </b-modal>

      <b-modal :active.sync="showMFAModal" has-modal-card>
        <MultiFactorModal />
      </b-modal>

    </div>
  </form>
</template>

<script>

import ChangePasswordModal from '@/views/modals/ChangePasswordModal'
import MultiFactorModal from '@/views/modals/MultiFactorModal'

export default {
  data () {
    return {
      name: '',
      email: '',
      showPasswordModal: false,
      showMFAModal: false
    }
  },

  props: {
    user: {
      default: {}
    }
  },

  mounted() {
    this.name = this.user.name
    this.email = this.user.email
  },

  methods: {
    changePassword() {
      this.showPasswordModal = true
    },

    multiFactorAuth() {
      this.showMFAModal = true
    },

    saveInfo() {
      this.user.name = this.name
      this.user.email = this.email
      this.$store.dispatch('authorizedFetch', {
        method: 'post',
        endpoint: '/api/user/update',
        body: this.user
      })
        .then(response => {
          return response.json()
        })
        .then(json => {
          this.$buefy.toast.open({ message: "Successfully updated information.", type: "is-success" })
        })
      this.$parent.close()
    }
  },
  components: {
    ChangePasswordModal,
    MultiFactorModal
  }
}
</script>
