<template>
  <div id="login">
    <div id="login-form" class="px-10 pb-8">
      <img
        class="mb-10 w-60 h-auto"
        src="@/assets/logoEvoluteTop.png"
        alt="LOGO"
      />
      <FormInput
        v-model="email"
        placeholder="Email"
        fully-rounded
        class="my-2"
        @keyup.enter.native="login()"
        v-show="!req2FA"
      />
      <FormInput
        v-model="password"
        placeholder="Password"
        type="password"
        fully-rounded
        class="my-2"
        @keyup.enter.native="login()"
        v-show="!req2FA"
      />
      <FormInput
        v-model="code"
        placeholder="Authentication Code"
        fully-rounded
        class="my-2"
        @keyup.enter.native="login()"
        v-if="req2FA"
      />
      <b-tooltip label="Enter multi-factor authentication code to login" append-to-body multilined v-if="req2FA">
        <b-icon icon="help-circle-outline" size="is-small"></b-icon>
      </b-tooltip>
      <ActionButton
        primary
        fully-rounded
        dynamicWidth
        class="w-full px-4 mt-2 mb-4"
        @click="login()"
      >
        <span v-if="!req2FA">Sign in</span>
        <span v-else>Enter code</span>
      </ActionButton>
      <router-link to="/forgotpassword" class="login-link" v-if="!req2FA">
        Forgot password
      </router-link>
      <div class="login-link" v-if="req2FA" style="cursor:pointer;" @click="reset()">
        Reset login
      </div>
      <div class="app-notice flex flex-col items-center bg-white rounded-2xl mt-4" v-if="showAppNotice && !req2FA">
        <div
          class="flex flex-col items-center border-b border-gray-200 px-6 py-4"
        >
          <p class="text-lg mb-1">Are you an Evolute user?</p>
          <p class="text-xs">Try our new Android/iOS App on your phone! Click below to get started.</p>
        </div>
        <div class="flex items-center px-14">
          <div class="py-2 border-r border-gray-200">
            <span class="text-sm my-2 text-blue-600 cursor-pointer pr-12" @click="appRedirect()">Download</span>
          </div>
          <div class="py-2">
            <span class="text-sm my-2 text-blue-600 cursor-pointer pl-16" @click="showAppNotice = false">Close</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import FormInput from '@/components/form/Input';
import ActionButton from '@/components/action/Button';

export default {
  name: 'LoginNew',
  components: {
    FormInput,
    ActionButton
  },
  data() {
    return {
      email: '',
      password: '',
      code: '',
      req2FA: false,
      showAppNotice: true,
      userAgent: null
    };
  },
  created() {
    this.$store.commit('setBearerToken', '')
    this.userAgent = window.navigator.userAgent
  },
  mounted() {
    this.clearStorage()
  },
  methods: {
    clearStorage() {
      this.$store.dispatch('clearPersistedState')
      window.sessionStorage.clear()
      window.localStorage.clear()
    },
    login() {
      if (!(this.email && this.password)) {
        this.$buefy.toast.open({
          message: 'Please fill out required fields.',
          type: 'is-primary'
        })
      } else {
        var body = {
          email: this.email.trim().toLowerCase(),
          password: this.password,
          code: this.code
        }
        var hasResponse = false

        fetch(this.$store.state.url + '/id/login', {
          method: 'post',
          body: JSON.stringify(body)
        })
          .then(response => {
            hasResponse = true
            if (response.ok) {
              return response.json()
            } else if (response.status === 403) {
              response.json().then(data => {
                this.$buefy.toast.open({ message: `Your account has been blocked. Try again in ${data.message} minutes`, type: "is-warning" })
              })
              return null
            } else if (response.status === 502) {
              this.$buefy.toast.open({ message: `The server is under maintenance. Please check back later`, type: "is-warning" })
              return null
            } else {
              if (this.req2FA) {
                this.$buefy.toast.open({ message: "Incorrect Authentication Code", type: "is-danger" })
              } else {
                this.$buefy.toast.open({ message: "Incorrect Email or Password", type: "is-danger" })
              }
              return null
            }
          })
          .then(json => {
            if (json.req2FA) {
              this.$buefy.toast.open({ message: `Please enter multi-factor authentication code`, type: "is-warning" })
              this.req2FA = true
            } else {
              this.$store.commit('setBearerToken', json.authorization)
              this.$store.dispatch('authorizedFetch', {
                method: 'get',
                endpoint: '/api/user/info'
              })
                .then(response => {
                  return response.json()
                })
                .then(json => {
                  if (json.class === "superuser" || json.class === "supervisor" || json.class === "masteradmin") {
                    this.$router.push({ path: '/buildingSelection' })
                  } else if (json.class === "admin") {
                    this.$store.dispatch('authorizedFetch', {
                      method: 'post',
                      endpoint: '/api/evdirect/building/admin/get',
                      body: { userUUID: json.uuid }
                    })
                      .then(response => {
                        return response.json()
                      })
                      .then(json => {
                        this.$store.state.building = json.building
                        this.$router.push({ path: '/panelSelection' })
                      })
                  } else if (json.class === "manager") {
                    this.$router.push({ path: '/managerHome' })
                  } else if (json.class === "technician") {
                    this.$router.push({ path: '/technicianHome' })
                  } else {
                    this.$router.push({ path: '/UserPage' })
                  }
                  this.$buefy.toast.open({ message: `Welcome, ${json.name}.`, type: "is-success" })
                })
            }
          })
          .catch(err => {
            if (!hasResponse) {
              this.$buefy.toast.open({ message: `The server is under maintenance. Please check back later`, type: "is-warning" })
            }
            console.log(err)
          })
      }
    },
    reset() {
      this.req2FA = false
      this.code = ''
    },
    appRedirect() {
      if (this.userAgent.match(/iPhone/)) {
        window.location.href = "https://apps.apple.com/us/app/evolute/id6447584289"
      } else if (this.userAgent.match(/Android/)) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.evdirect.evolute&pli=1"
      }
    }
  }
};
</script>

<style scoped>
#login {
  @apply flex justify-center items-center;
  @apply min-h-screen w-full;
  background: url('../assets/images/full_waves.png') no-repeat center/cover;
}

#login-form {
  @apply flex flex-col;
  @apply items-center;
  @apply w-full max-w-xs;
  @apply min-h-screen;
  @apply justify-center;
}

.login-link {
  @apply text-accent;
}

/* specific for desktop screens */
@media (min-width: 801px) {
  .app-notice {
    @apply hidden;
  }
}
</style>
