<template>
  <input
    class="custom-input rounded-lg"
    :class="{ 'shadow-xl': shadow, 'rounded-full': fullyRounded }"
    :type="type"
    :placeholder="placeholder"
    :readonly="readonly"
    v-model="value"
    v-on="events"
    @input="Update"
  />
</template>

<script>

export default {
  name: 'FormInput',
  data() {
    return {
      value: ''
    }
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    shadow: {
      type: Boolean,
      default: false
    },
    fullyRounded: {
      type: Boolean,
      default: false
    },
    events: {
      type: Object,
      default: () => ({})
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    Update() {
      this.$emit('input', this.value)
    }
  }
};
</script>

<style scoped>
.custom-input {
  @apply w-full;
  @apply bg-brand-gray bg-opacity-10 outline-none;
  @apply placeholder-brand-placeholder;
  @apply py-4 px-9;
  @apply leading-3;
  @apply font-medium;
}
</style>
