<template>
  <form action="">
    <div class="modal-card" style="width: 100vw; max-width: 500px; margin: auto;">

      <header class="modal-card-head">
        <p class="modal-card-title">Multi-Factor Authentication</p>
      </header>
      <section class="modal-card-body">
        <div style="display: flex; justify-content: center" v-if="url == ''">
          <p style="font-weight: bold;"> Status:&nbsp; </p>
          <p v-if="user.mfaActive" style="color:green">ENABLED</p>
          <p v-else style="color:red">DISABLED</p>
        </div>

        <div>
          <br>
          <p>2FA with authenticator apps adds an extra layer of security to your accounts. Instead of just a password, you'll also need a time-sensitive code from the authenticator app.</p>
          <br>
          <p>Hint:<br>Think of it as having a digital bodyguard for your accounts. Once enabled, you'll use your password as usual, but the authenticator app generates a unique code that changes every few seconds. This code is your virtual key to access your account securely.</p>
          <br>
        </div>
        <div v-if="!user.mfaActive">
          <p>How to use it:</p>
          <br>
          <p>1. Download an authenticator app (e.g., Google Authenticator, Authy).</p>
          <p>2. Enable 2FA below.</p>
          <p>3. Scan the QR code shown with the authenticator app.</p>
          <p>4. The app will generate codes; use them along with your password during login.</p>
          <p>5. Keep your phone secure; this code adds an extra layer to your account's defense.</p>
          <br>
          <p>Remember, it's like having a secret handshake only you and your app know!</p>
          <br>
        </div>

        <b-field label="QR Code" v-if="url != ''">
          <img :src="url" />
        </b-field>

        <div v-if="url != ''">
          <p>Protect your account with TOTP QR code! Safeguard your data by using an authenticator app to generate secure, time-sensitive passcodes. Remember: Keep this QR code confidential; sharing compromises your account's security.</p>
          <br>
        </div>

        <b-field label="Authentication Code" v-if="authCode != ''">
          {{ authCode }}
        </b-field>

        <b-field label="Activation Code" v-if="url != ''">
            <b-input
                type="text"
                v-model="passcode"
                required>
            </b-input>
        </b-field>

        <b-field v-if="url == '' && !user.mfaActive">
          <button
            style="margin-top:20px;width:100%;"
            class="button is-primary"
            type="button"
            @click="genMFA"
          >
            <b-icon icon="check"></b-icon>
            <span> Activate Multi-Factor Authentication </span>
          </button>
        </b-field>

        <b-field v-if="url != ''">
          <button
            style="margin-top:20px;width:100%;"
            class="button is-primary"
            type="button"
            @click="changeMFA"
          >
            <b-icon icon="qrcode"></b-icon>
            <span> Submit Code </span>
          </button>
        </b-field>

        <b-field v-if="user.mfaActive">
          <button
            style="margin-top:20px;width:100%;"
            class="button is-primary"
            type="button"
            @click="deactivateMFA"
          >
            <b-icon icon="cancel"></b-icon>
            <span> Deactivate Multi-Factor Authentication </span>
          </button>
        </b-field>
      </section>

      <footer class="modal-card-foot" style="display: flex;justify-content: flex-end">
          <button class="button" type="button" @click="$parent.close()">Close</button>
      </footer>

    </div>
  </form>
</template>

<script>

export default {
  data () {
    return {
      authCode: '',
      passcode: '',
      url: '',
      user: {}
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    fetchData () {
      this.$store.dispatch('authorizedFetch', {
        method: 'get',
        endpoint: '/api/user/info'
      })
        .then(response => {
          return response.json()
        })
        .then(json => {
          this.user = json
        })
        .catch(err => {
          console.log(err)
        })
    },
    genMFA() {
      this.$store.dispatch('authorizedFetch', {
        method: "get",
        endpoint: '/api/user/mfaGenerate'
      })
        .then(response => {
          return response.json()
        })
        .then(json => {
          this.url = "data:image/png;base64," + json.qrCode
          this.authCode = json.secret
        })
    },
    deactivateMFA() {
      this.$buefy.dialog.confirm({
        title: `Deactivating multi-factor authentication`,
        message: `Are you sure you want to do this?`,
        confirmText: `Proceed`,
        type: 'is-info',
        hasIcon: true,
        onConfirm: () => {
          this.changeMFA()
        }
      })
    },
    changeMFA() {
      this.$store.dispatch('authorizedFetch', {
        method: 'post',
        endpoint: '/api/user/mfa',
        body: {
          uuid: this.user.uuid,
          activate: !this.user.mfaActive,
          code: this.passcode
        }
      })
        .then(response => {
          return response.json()
        })
        .then(json => {
          if (json.success) {
            this.$buefy.toast.open({ message: "Successfully updated authentication settings", type: "is-success" })
            this.fetchData()
            this.$parent.close()
          } else {
            this.$buefy.toast.open({ message: "Could not update authentication settings", type: "is-danger" })
          }
        })
    }
  }
}
</script>
