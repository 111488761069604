var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'router-link' : 'button',{tag:"component",staticClass:"action-button rounded-xl uppercase",class:[
    { 'fixed-width': !_vm.dynamicWidth },
    { 'rounded-full': _vm.fullyRounded },
    { 'shadow-xl': _vm.shadow },
    { 'action-link': _vm.to },
    _vm.transparent
      ? [
          'transparent-action',
          {
            'transparent-accent': _vm.accent,
            'transparent-primary': _vm.primary,
            'transparent-secondary': _vm.secondary,
          } ]
      : [
          'solid-action',
          {
            'solid-accent': _vm.accent,
            'solid-primary': _vm.primary,
            'solid-secondary': _vm.secondary,
          } ]
  ],attrs:{"to":_vm.to},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }